<template>
    <BaseSection section-width="content">
        <template #default>
            <CardList
                class="card-list--spacing-sm"
            >
                <template v-for="download in downloads">
                    <DownloadCard
                        v-if="download.file"
                        :key="download.id"
                        :download="download"
                        :is-inline="true"
                    />
                </template>
            </CardList>
        </template>
    </BaseSection>
</template>

<script setup>
defineProps({
    downloads: {
        type: Array,
        required: true,
        default: () => []
    },
});
</script>
