<template>
    <BaseSection section-width="content">
        <template #default>
            <BaseQuote
                :quote="quote"
                :author="author"
            />
        </template>
    </BaseSection>
</template>

<script setup>
defineProps({
    quote: {
        type: String,
        default: null,
    },
    author: {
        type: String,
        default: null,
    },
});
</script>
