<template>
    <div class="base-video">
        <div v-if="videoEmbed">
            <DnEmbed
                v-if="videoEmbed.isSafe"
                :url="videoEmbed.url"
            >
                <template
                    v-for="name in Object.keys($slots)"
                    v-slot:[name]="scope"
                >
                    <slot
                        :name="name"
                        v-bind="scope"
                    />
                </template>
            </DnEmbed>
            <div v-else>
                <!-- Embedded video is not safe -->
                De video kan niet worden afgespeeld.
            </div>
        </div>
        <div v-else>
            <!-- Optional TODO: Custom video player from Craft assets -->
            De video kan niet worden afgespeeld.
        </div>
    </div>
</template>

<script setup>
import DnEmbed from '@digitalnatives/video-embed';

const props = defineProps({
    video: {
        type: Object,
        default: null
    }
});

const videoEmbed = ref(props.video?.embeddedAsset ?? null);
</script>
