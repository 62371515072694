<template>
    <BaseSection
        :section-width="sectionWidth"
        background-style="lightblue"
        class="section--video"
    >
        <template
            v-if="singleVideo"
            #default
        >
            <BaseVideo :video="singleVideo">
                <template
                    v-if="caption"
                    #caption
                >
                    <div class="video__caption" v-html="caption" /> <!-- eslint-disable-line -->
                </template>
            </BaseVideo>
        </template>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    video: {
        type: Object,
        default: null
    },
    caption: {
        type: String,
        default: null
    },
    sectionWidth: {
        type: String,
        default: 'page'
    }
});

const singleVideo = ref(props.video?.[0] ?? null);
</script>

<style lang="less">
.section--video {
    .video__caption {
        p {
            .typography-small();
        }
    }

    figure figcaption  {
        margin: 0;
    }

    @media @q-md-min {
        .video__caption {
            margin-top: var(--spacing-2xs);
        }
    }
}
</style>
