<template>
    <figure class="quote">
        <blockquote class="quote__content">
            <slot>{{ quote }}</slot>
        </blockquote>
        <figcaption
            v-if="author || $slots.author"
            class="quote__author"
        >
            <slot name="author">{{ author }}</slot>
        </figcaption>
    </figure>
</template>

<script setup>
defineProps({
    quote: {
        type: String,
        required: true,
        default: null,
    },
    author: {
        type: String,
        default: null,
    },
});
</script>

<style lang="less" src="./BaseQuote.less" />
